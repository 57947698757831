document.addEventListener('DOMContentLoaded', function () {
    const inventarioPage = document.getElementById('inventario-page');

    if (inventarioPage) {
        // Funcionalidad del buscador
        const inputBuscar = document.getElementById('buscar-material');
        const tablaInventario = document.getElementById('tabla-inventario');
        const filasTabla = tablaInventario.getElementsByTagName('tr');

        if (inputBuscar) {
            inputBuscar.addEventListener('keyup', function () {
                const filtro = inputBuscar.value.toLowerCase();
                for (let i = 1; i < filasTabla.length; i++) {
                    const celdas = filasTabla[i].getElementsByTagName('td');
                    let coincide = false;
                    for (let j = 0; j < celdas.length; j++) {
                        if (celdas[j].innerText.toLowerCase().includes(filtro)) {
                            coincide = true;
                            break;
                        }
                    }
                    filasTabla[i].style.display = coincide ? '' : 'none';
                }
            });
        }

        // Agregar Material
        const btnAgregarItem = document.getElementById('btn-agregar-item');
        const btnEliminarItem = document.getElementById('btn-eliminar-item');

        if (btnAgregarItem) {
            btnAgregarItem.addEventListener('click', function () {
                abrirModalAgregarMaterial();
            });
        }

        if (btnEliminarItem) {
            btnEliminarItem.addEventListener('click', function () {
                eliminarMaterial();
            });
        }

        // Links para Detalles, Usos y Proveedores
        const modalLinks = document.querySelectorAll('#tabla-inventario a');
        modalLinks.forEach(link => {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                const idMaterial = this.getAttribute('href').split('=')[1];
                const tipo = this.innerText.toLowerCase();

                if (tipo === 'detalles') {
                    abrirModalDetalles(idMaterial);
                } else if (tipo === 'usos') {
                    abrirModalUsos(idMaterial);
                } else if (tipo === 'proveedores') {
                    abrirModalProveedores(idMaterial);
                }
            });
        });
    }

    // Función para abrir el modal de agregar material
    function abrirModalAgregarMaterial() {
        const modalAgregar = document.createElement('div');
        modalAgregar.classList.add('inventario-modal-overlay');
        modalAgregar.innerHTML = `
            <div class="inventario-modal-content">
                <form id="form-agregar-material" action="../admin/agregar_material.php" method="POST">
                    <h3>Agregar Material</h3>
                    <label for="nombre_item">Nombre del Material:</label>
                    <input type="text" name="nombre_item" required>
                    <label for="cantidad">Cantidad:</label>
                    <input type="number" name="cantidad" required>
                    <button type="submit">Agregar Material</button>
                    <button type="button" id="btn-cerrar-modal">Cerrar</button>
                </form>
            </div>
        `;
        document.body.appendChild(modalAgregar);

        document.getElementById('btn-cerrar-modal').addEventListener('click', function () {
            document.body.removeChild(modalAgregar);
        });

        document.getElementById('form-agregar-material').addEventListener('submit', function (e) {
            e.preventDefault();
            const formData = new FormData(this);

            fetch('../admin/agregar_material.php', {
                method: 'POST',
                body: formData
            })
                .then(response => response.text())
                .then(data => {
                    alert(data);
                    location.reload();
                })
                .catch(error => console.error('Error:', error));
        });
    }

    // Función para eliminar material
    function eliminarMaterial() {
        const id = prompt('Ingrese el ID del material a eliminar:');
        if (id) {
            const formData = new FormData();
            formData.append('id', id);

            fetch('../admin/eliminar_material.php', {
                method: 'POST',
                body: formData
            })
                .then(response => response.text())
                .then(data => {
                    alert(data);
                    location.reload();
                })
                .catch(error => console.error('Error:', error));
        }
    }

    // Funciones para manejar los modales de Detalles, Usos y Proveedores

    // Modal para Detalles
    function abrirModalDetalles(idMaterial) {
        abrirModalGenerico('detalles', idMaterial);
    }

    // Modal para Usos
    function abrirModalUsos(idMaterial) {
        abrirModalGenerico('usos', idMaterial);
    }

    // Modal para Proveedores
    function abrirModalProveedores(idMaterial) {
        abrirModalGenerico('proveedores', idMaterial, 'proveedor');
    }

    // Función genérica para abrir los modales
    function abrirModalGenerico(tipo, idMaterial, singular = tipo.slice(0, -1)) {
        const modal = document.createElement('div');
        modal.classList.add('inventario-modal-overlay');
        modal.innerHTML = `
            <div class="inventario-modal-content">
                <h3>Gestionar ${capitalize(singular)}</h3>
                <form id="form-agregar-${tipo}" action="../admin/agregar_${singular}.php" method="POST">
                    <input type="hidden" name="id_material" value="${idMaterial}">
                    <label for="${tipo}_input">Nuevo ${capitalize(singular)}:</label>
                    <input type="text" name="${singular}" required>
                    <button type="submit">Agregar ${capitalize(singular)}</button>
                </form>
                <h4>Listado de ${capitalize(tipo)}</h4>
                <table class="modal-listado">
                    <thead>
                        <tr>
                            <th>${capitalize(singular)}</th>
                            <th>Acción</th>
                        </tr>
                    </thead>
                    <tbody id="listado-${tipo}">
                    </tbody>
                </table>
                <button type="button" id="btn-cerrar-modal">Cerrar</button>
            </div>
        `;
        document.body.appendChild(modal);

        fetch(`../admin/listar_${tipo}.php?id_material=${idMaterial}`)
            .then(response => response.json())
            .then(data => {
                const listado = document.getElementById(`listado-${tipo}`);
                listado.innerHTML = data.map(item => `
                    <tr>
                        <td>${item.nombre}</td>
                        <td><button class="btn-eliminar-${tipo}" data-id="${item.id}">Eliminar</button></td>
                    </tr>`).join('');

                document.querySelectorAll(`.btn-eliminar-${tipo}`).forEach(button => {
                    button.addEventListener('click', function () {
                        const idItem = this.getAttribute('data-id');
                        eliminarElemento(tipo, idItem, idMaterial);
                    });
                });
            })
            .catch(error => console.error('Error:', error));

        document.getElementById('btn-cerrar-modal').addEventListener('click', function () {
            document.body.removeChild(modal);
        });

        document.getElementById(`form-agregar-${tipo}`).addEventListener('submit', function (e) {
            e.preventDefault();
            const formData = new FormData(this);

            fetch(`../admin/agregar_${singular}.php`, {
                method: 'POST',
                body: formData
            })
                .then(response => response.text())
                .then(data => {
                    alert(data);
                    location.reload();
                })
                .catch(error => console.error('Error:', error));
        });
    }

    // Función para eliminar elementos (Detalles, Usos, Proveedores)
    function eliminarElemento(tipo, idItem, idMaterial) {
        const formData = new FormData();
        formData.append('id', idItem);
        formData.append('id_material', idMaterial);

        fetch(`../admin/eliminar_${tipo.slice(0, -1)}.php`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.text())
            .then(data => {
                alert(data);
                location.reload();
            })
            .catch(error => console.error('Error:', error));
    }

    // Capitalizar texto
    function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
});
