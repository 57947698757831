document.addEventListener('DOMContentLoaded', function () {
    const pendientesPage = document.getElementById('planificador-page');
    
    if (pendientesPage) {
        const modalRevOT = document.getElementById('modal-rev-ot');
        const contenidoRevOT = document.getElementById('contenido-rev-ot');
        const closeModalRevOT = document.querySelector('.close-button-rev-ot');

        // Función para cargar los detalles de la OT
        pendientesPage.addEventListener('click', function (event) {
            if (event.target.classList.contains('btn-rev-ot')) {
                const trabajoId = event.target.getAttribute('data-id');
                fetch(`../admin/get_datos_ot.php?id=${trabajoId}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            contenidoRevOT.innerHTML = `
                                <p><strong>Hora de Inicio:</strong> ${data.hora_inicio || 'N/A'}</p>
                                <p><strong>Hora de Término:</strong> ${data.hora_termino || 'N/A'}</p>
                                <p><strong>Fecha de Término:</strong> ${data.fecha_termino || 'N/A'}</p>
                                <p><strong>Repuestos Utilizados:</strong> ${data.repuestos_utilizados || 'N/A'}</p>
                                <p><strong>Comentarios:</strong> ${data.comentarios || 'N/A'}</p>
                            `;
                            modalRevOT.style.display = 'flex';
                        } else {
                            console.error('Error: No se encontraron datos de la OT');
                        }
                    })
                    .catch(error => {
                        console.error('Error al cargar detalles de la OT:', error);
                    });
            }
        });

        // Cerrar el modal de revisión de OT
        closeModalRevOT.addEventListener('click', function () {
            modalRevOT.style.display = 'none';
        });

        // Cerrar el modal si se hace clic fuera del contenido del modal
        window.addEventListener('click', function (event) {
            if (event.target === modalRevOT) {
                modalRevOT.style.display = 'none';
            }
        });
    }
});
