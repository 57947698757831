document.addEventListener('DOMContentLoaded', function () {
    const addMaquinariasPage = document.querySelector('.add-maquinarias-page');

    if (addMaquinariasPage) {  // Solo ejecuta el código si está en la página con la clase 'add-maquinarias-page'
        const forms = document.querySelectorAll('.add-maquinarias-form-contenedor form');
        let currentIndex = 0; // Índice de la tarjeta que está en primer plano

        // Función para actualizar la visibilidad y el color de las tarjetas
        function updateForms() {
            forms.forEach((form, index) => {
                if (index < currentIndex) {
                    form.style.top = `${index * 20}px`;
                    form.style.zIndex = forms.length - index;
                    form.style.opacity = "0.3"; // Hacer más gris las tarjetas detrás
                } else if (index > currentIndex) {
                    form.style.top = `${(index - 1) * 30}px`;
                    form.style.zIndex = forms.length - index - 1;
                    form.style.opacity = "0.3"; // Hacer más gris las tarjetas detrás
                } else {
                    form.style.top = `${currentIndex * 30}px`;
                    form.style.zIndex = forms.length;
                    form.style.opacity = "1"; // La tarjeta principal está completamente visible
                }
            });
        }

        // Inicializar las tarjetas
        updateForms();

        // Añadir scroll para navegar entre las tarjetas
        window.addEventListener('wheel', function (event) {
            if (event.deltaY < 0) {  // Scroll hacia arriba
                if (currentIndex > 0) {
                    currentIndex--;
                    updateForms();
                }
            } else if (event.deltaY > 0) {  // Scroll hacia abajo
                if (currentIndex < forms.length - 1) {
                    currentIndex++;
                    updateForms();
                }
            }
        });

        // Evento de clic para mover la tarjeta seleccionada al frente
        forms.forEach((form, index) => {
            form.addEventListener('click', function () {
                currentIndex = index;
                updateForms();
            });
        });
    }
});
 