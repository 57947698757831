document.addEventListener('DOMContentLoaded', function () {
    const pendientesPage = document.getElementById('pendientes-page');
    
    if (pendientesPage) {
        const tablaTrabajos = pendientesPage.querySelector('.tabla'); // Tabla de trabajos
        const modalDetalles = pendientesPage.querySelector('#modal-detalles');
        const modalContenido = pendientesPage.querySelector('#contenido-detalles');
        const closeModalDetalles = pendientesPage.querySelector('.close-detalles-button'); // Cambié el nombre aquí

        // Función para cargar los detalles del trabajo
        tablaTrabajos.addEventListener('click', function (event) {
            if (event.target.classList.contains('btn-detalles')) {
                const trabajoId = event.target.getAttribute('data-id');
                fetch(`../admin/get_trabajo_detalle.php?id=${trabajoId}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            modalContenido.innerHTML = `
                                <p><strong>Nombre del Trabajo:</strong> ${data.trabajo_asignado || 'N/A'}</p>
                                <p><strong>Área:</strong> ${data.nombre_area || 'N/A'}</p>
                                <p><strong>Línea:</strong> ${data.nombre_linea || 'N/A'}</p>
                                <p><strong>Maquinaria:</strong> ${data.nombre_maquinaria || 'N/A'}</p>
                                <p><strong>Equipo:</strong> ${data.nombre_equipo || 'N/A'}</p>
                                <p><strong>Evento:</strong> ${data.evento || 'N/A'}</p>
                                <p><strong>Causa:</strong> ${data.causa || 'N/A'}</p>
                                <p><strong>Repuestos:</strong> ${data.repuestos || 'N/A'}</p>
                            `;
                            modalDetalles.style.display = 'flex';
                        } else {
                            console.error('Error: No se encontraron datos del trabajo');
                        }
                    })
                    .catch(error => {
                        console.error('Error al cargar detalles del trabajo:', error);
                    });
            }
        });

        // Cerrar el modal de detalles del trabajo
        closeModalDetalles.addEventListener('click', function () {
            modalDetalles.style.display = 'none';
        });

        // Cerrar el modal si se hace clic fuera del contenido del modal
        window.addEventListener('click', function (event) {
            if (event.target === modalDetalles) {
                modalDetalles.style.display = 'none';
            }
        });
    }
});
