console.log('Desde el add-maquina.js');

document.addEventListener('DOMContentLoaded', function () {
    const areaSelect = document.getElementById('id_area');
    const lineaSelect = document.getElementById('id_linea');
    const maquinariaSelect = document.getElementById('id_maquinaria');
    const equipoSelect = document.getElementById('id_equipo');

    // Asegurarse de que los elementos existen antes de agregar listeners
    if (areaSelect) {
        // Obtener las líneas según el área seleccionada
        areaSelect.addEventListener('change', function () {
            const idArea = this.value;
            if (idArea) {
                fetch(`get_lineas.php?id_area=${idArea}`)
                    .then(response => response.json())
                    .then(data => {
                        // Limpiar el select de línea
                        lineaSelect.innerHTML = '<option value="">Seleccione una línea</option>';
                        data.forEach(linea => {
                            const option = document.createElement('option');
                            option.value = linea.id_linea;
                            option.textContent = linea.nombre_linea;
                            lineaSelect.appendChild(option);
                        });
                    });
            } else {
                lineaSelect.innerHTML = '<option value="">Seleccione una línea</option>';
            }
        });
    }

    if (lineaSelect) {
        // Obtener las maquinarias según la línea seleccionada
            lineaSelect.addEventListener('change', function () {
                const idLinea = this.value;
                if (idLinea) {
                    fetch(`get_maquinarias.php?id_linea=${idLinea}`)  // Aquí debes cambiar el nombre del archivo
                        .then(response => response.json())
                        .then(data => {
                            maquinariaSelect.innerHTML = '<option value="">Seleccione una maquinaria</option>';
                            data.forEach(maquinaria => {
                                const option = document.createElement('option');
                                option.value = maquinaria.id_maquinaria;
                                option.textContent = maquinaria.nombre_maquinaria;
                                maquinariaSelect.appendChild(option);
                            });
                        });
                } else {
                    maquinariaSelect.innerHTML = '<option value="">Seleccione una maquinaria</option>';
                }
            });
    }

    if (maquinariaSelect) {
        // Obtener los equipos según la maquinaria seleccionada
        maquinariaSelect.addEventListener('change', function () {
            const idMaquinaria = this.value;
            if (idMaquinaria) {
                fetch(`get_equipos.php?id_maquinaria=${idMaquinaria}`)
                    .then(response => response.json())
                    .then(data => {
                        equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
                        data.forEach(equipo => {
                            const option = document.createElement('option');
                            option.value = equipo.id_equipo;
                            option.textContent = equipo.nombre_equipo;
                            equipoSelect.appendChild(option);
                        });
                    });
            } else {
                equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
            }
        });
    }
});

document.addEventListener('DOMContentLoaded', function () {
    const maquinariaSelect = document.getElementById('id_maquinaria');
    const equipoSelect = document.getElementById('id_equipo');
    const equipoModeloSelect = document.getElementById('id_equipo_modelo'); // Para el formulario de modelo

    // Asegúrate de que se maneja el evento para el selector de maquinarias
    if (maquinariaSelect) {
        maquinariaSelect.addEventListener('change', function () {
            const idMaquinaria = this.value;
            if (idMaquinaria) {
                fetch(`get_equipos.php?id_maquinaria=${idMaquinaria}`)
                    .then(response => response.json())
                    .then(data => {
                        equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
                        equipoModeloSelect.innerHTML = '<option value="">Seleccione un equipo</option>'; // Limpiar el selector del modelo
                        data.forEach(equipo => {
                            const option = document.createElement('option');
                            option.value = equipo.id_equipo;
                            option.textContent = equipo.nombre_equipo;
                            equipoSelect.appendChild(option);

                            // También añadimos las opciones para el formulario de modelos
                            const optionModelo = document.createElement('option');
                            optionModelo.value = equipo.id_equipo;
                            optionModelo.textContent = equipo.nombre_equipo;
                            equipoModeloSelect.appendChild(optionModelo);
                        });
                    })
                    .catch(error => console.error('Error al obtener los equipos:', error));
            } else {
                equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
                equipoModeloSelect.innerHTML = '<option value="">Seleccione un equipo</option>'; // Limpiar ambos selectores si no hay selección
            }
        });
    }
});