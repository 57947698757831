document.addEventListener('DOMContentLoaded', function () {
    const planificadorPage = document.getElementById('planificador-page');

    if (planificadorPage) {
        const modal = document.getElementById('modal-añadir-trabajo');
        const btnAbrirModal = document.getElementById('btn-abrir-modal');
        const closeButtons = document.querySelectorAll('.close-button'); 
        const mensajeTrabajo = document.getElementById('mensaje-trabajo');
        const tablaTrabajos = document.getElementById('tabla-trabajos');
        const buscador = document.getElementById('buscador-trabajos');
        const modalDetalles = document.getElementById('modal-detalles-trabajo');
        const modalContenido = document.getElementById('detalles-trabajo-contenido');

        // Elementos del modal de añadir trabajos
        const areaSelect = document.getElementById('area');
        const lineaSelect = document.getElementById('linea');
        const maquinaSelect = document.getElementById('maquina');
        const equipoSelect = document.getElementById('equipo');

        // Función para mostrar mensajes
        function mostrarMensaje(texto, tipo) {
            mensajeTrabajo.innerHTML = `<span class="${tipo}">${texto}</span>`;
            mensajeTrabajo.style.display = 'block';
            setTimeout(function () {
                mensajeTrabajo.style.display = 'none';
            }, 3000);
        }

        // Mostrar modal al hacer clic en el botón de añadir trabajo
        btnAbrirModal.addEventListener('click', function () {
            modal.style.display = 'flex';
        });

        // Cerrar modal al hacer clic en los botones de cierre
        closeButtons.forEach(button => {
            button.addEventListener('click', function () {
                const parentModal = button.closest('.modal-planificador');
                parentModal.style.display = 'none';
            });
        });

        // Cerrar modal al hacer clic fuera del contenido del modal
        window.addEventListener('click', function (e) {
            if (e.target === modal) {
                modal.style.display = 'none';
            }
        });

        // Función para eliminar un trabajo
        function eliminarTrabajoPlanificador(idTrabajo) {
            const formData = new FormData();
            formData.append('id', idTrabajo);

            fetch('../admin/eliminar_trabajo_planificador.php', {
                method: 'POST',
                body: formData
            })
            .then(response => response.json()) 
            .then(data => {
                if (data.success) {
                    alert(data.message);
                    // Recargar la página automáticamente después de la eliminación exitosa
                    setTimeout(() => {
                        window.location.reload(); // Recarga la página
                    }, 1000); // Espera 1 segundo antes de recargar
                } else {
                    alert(data.message);
                }
            })
            .catch(error => {
                console.error('Error al eliminar el trabajo:', error);
                alert('Error al eliminar el trabajo');
            });
        }

        // Asociar el evento de click para los botones de eliminar
        tablaTrabajos.addEventListener('click', function (event) {
            if (event.target.classList.contains('btn-eliminar-planificador')) {
                const trabajoId = event.target.getAttribute('data-id');
                if (confirm('¿Estás seguro de que deseas eliminar este trabajo?')) {
                    eliminarTrabajoPlanificador(trabajoId);
                }
            }
        });

        // Función para cargar los detalles del trabajo
        tablaTrabajos.addEventListener('click', function (event) {
            if (event.target.classList.contains('btn-detalles')) {
                const trabajoId = event.target.getAttribute('data-id');
                fetch(`../admin/get_trabajo_detalle.php?id=${trabajoId}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            modalContenido.innerHTML = `
                                <p><strong>Nombre del Trabajo:</strong> ${data.trabajo_asignado || 'N/A'}</p>
                                <p><strong>Área:</strong> ${data.nombre_area || 'N/A'}</p>
                                <p><strong>Línea:</strong> ${data.nombre_linea || 'N/A'}</p>
                                <p><strong>Maquinaria:</strong> ${data.nombre_maquinaria || 'N/A'}</p>
                                <p><strong>Equipo:</strong> ${data.nombre_equipo || 'N/A'}</p>
                                <p><strong>Evento:</strong> ${data.evento || 'N/A'}</p>
                                <p><strong>Causa:</strong> ${data.causa || 'N/A'}</p>
                                <p><strong>Repuestos:</strong> ${data.repuestos || 'N/A'}</p>
                            `;
                            modalDetalles.style.display = 'flex';
                        } else {
                            console.error('Error: No se encontraron datos del trabajo');
                        }
                    })
                    .catch(error => {
                        console.error('Error al cargar detalles del trabajo:', error);
                    });
            }
        });

        // Cerrar el modal de detalles del trabajo
        const closeModalDetalles = document.querySelector('.close-button');
        closeModalDetalles.addEventListener('click', function () {
            modalDetalles.style.display = 'none';
        });

        // Buscador en la tabla de trabajos
        buscador.addEventListener('input', function () {
            const searchText = buscador.value.toLowerCase();
            const rows = tablaTrabajos.querySelectorAll('tbody tr');
            rows.forEach(row => {
                const rowText = row.textContent.toLowerCase();
                if (rowText.includes(searchText)) {
                    row.style.display = '';
                } else {
                    row.style.display = 'none';
                }
            });
        });

        // Cargar líneas en función del área seleccionada
        areaSelect.addEventListener('change', function () {
            const areaId = areaSelect.value;
            if (areaId) {
                fetch(`../admin/get_lineas.php?id_area=${areaId}`)
                    .then(response => response.json())
                    .then(data => {
                        lineaSelect.innerHTML = '<option value="">Seleccione una línea</option>';
                        data.forEach(linea => {
                            lineaSelect.innerHTML += `<option value="${linea.id_linea}">${linea.nombre_linea}</option>`;
                        });
                    })
                    .catch(error => console.error('Error al cargar líneas:', error));
            } else {
                lineaSelect.innerHTML = '<option value="">Seleccione una línea</option>';
                maquinaSelect.innerHTML = '<option value="">Seleccione una maquinaria</option>';
                equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
            }
        });

        // Cargar maquinarias en función de la línea seleccionada
        lineaSelect.addEventListener('change', function () {
            const lineaId = lineaSelect.value;
            if (lineaId) {
                fetch(`../admin/get_maquinarias.php?id_linea=${lineaId}`)
                    .then(response => response.json())
                    .then(data => {
                        maquinaSelect.innerHTML = '<option value="">Seleccione una maquinaria</option>';
                        data.forEach(maquina => {
                            maquinaSelect.innerHTML += `<option value="${maquina.id_maquinaria}">${maquina.nombre_maquinaria}</option>`;
                        });
                    })
                    .catch(error => console.error('Error al cargar maquinarias:', error));
            } else {
                maquinaSelect.innerHTML = '<option value="">Seleccione una maquinaria</option>';
                equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
            }
        });

        // Cargar equipos en función de la maquinaria seleccionada
        maquinaSelect.addEventListener('change', function () {
            const maquinaId = maquinaSelect.value;
            if (maquinaId) {
                fetch(`../admin/get_equipos.php?id_maquinaria=${maquinaId}`)
                    .then(response => response.json())
                    .then(data => {
                        equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
                        data.forEach(equipo => {
                            equipoSelect.innerHTML += `<option value="${equipo.id_equipo}">${equipo.nombre_equipo}</option>`;
                        });
                    })
                    .catch(error => console.error('Error al cargar equipos:', error));
            } else {
                equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
            }
        });
    }
    if (planificadorPage) {
    const buscador = document.getElementById('buscador-trabajos');
    
    const tabla = document.getElementById('tabla-trabajos').getElementsByTagName('tbody')[0];

    buscador.addEventListener('input', function () {
        const filtro = buscador.value.toLowerCase();
        const filas = tabla.getElementsByTagName('tr');
        
        for (let i = 0; i < filas.length; i++) {
            const fila = filas[i];
            const columnas = fila.getElementsByTagName('td');
            let coincide = false;

            // Verifica si alguna columna contiene el texto del buscador
            for (let j = 0; j < columnas.length; j++) {
                const columnaTexto = columnas[j].textContent || columnas[j].innerText;
                if (columnaTexto.toLowerCase().indexOf(filtro) > -1) {
                    coincide = true;
                    break; // Si encuentra coincidencia en una columna, deja de buscar en las demás
                }
            }

            // Muestra u oculta la fila según la coincidencia
            fila.style.display = coincide ? '' : 'none';
        }
    });
    }
});
 