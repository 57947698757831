document.addEventListener('DOMContentLoaded', function () {
    const añadirTrabajosPage = document.getElementById('añadir-trabajos-page');

    if (añadirTrabajosPage) {
        const areaSelect = document.querySelector('#area');
        const lineaSelect = document.querySelector('#linea');
        const maquinaSelect = document.querySelector('#maquina');
        const equipoSelect = document.querySelector('#equipo');
        const fechaInicioInput = document.getElementById('fecha');
        const fechaTerminoInput = document.getElementById('fecha_termino');
        const tecnicosInput = document.getElementById('tecnicos');
        const abrirModalBtn = document.getElementById('abrir-modal-tecnicos');
        const modalTecnicos = document.getElementById('modal-tecnicos');
        const closeModalBtn = modalTecnicos.querySelector('.close-button');
        const guardarTecnicosBtn = document.getElementById('guardar-tecnicos');
        const checkboxes = document.querySelectorAll('.tecnico-checkbox');

        let tecnicosSeleccionados = [];

        // Manejar la selección de técnicos en el modal
        abrirModalBtn.addEventListener('click', function () {
            modalTecnicos.style.display = 'block';
        });

        closeModalBtn.addEventListener('click', function () {
            modalTecnicos.style.display = 'none';
        });

        window.addEventListener('click', function (event) {
            if (event.target === modalTecnicos) {
                modalTecnicos.style.display = 'none';
            }
        });

        guardarTecnicosBtn.addEventListener('click', function () {
            tecnicosSeleccionados = [];

            checkboxes.forEach(checkbox => {
                if (checkbox.checked) {
                    tecnicosSeleccionados.push(checkbox.value);
                }
            });

            tecnicosInput.value = tecnicosSeleccionados.join(' - ');  // Asignar el valor al input
            modalTecnicos.style.display = 'none';
        });

        // Selección de áreas, líneas, máquinas, equipos
        if (areaSelect) {
            areaSelect.addEventListener('change', function () {
                const idArea = this.value;
                if (idArea) {
                    fetch(`get_lineas.php?id_area=${idArea}`)
                        .then(response => response.json())
                        .then(data => {
                            lineaSelect.innerHTML = '<option value="">Seleccione una línea</option>';
                            data.forEach(linea => {
                                lineaSelect.innerHTML += `<option value="${linea.id_linea}">${linea.nombre_linea}</option>`;
                            });
                        })
                        .catch(error => console.error('Error al cargar líneas:', error));
                } else {
                    lineaSelect.innerHTML = '<option value="">Seleccione una línea</option>';
                    maquinaSelect.innerHTML = '<option value="">Seleccione una máquina</option>';
                    equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
                }
            });
        }

        if (lineaSelect) {
            lineaSelect.addEventListener('change', function () {
                const idLinea = this.value;
                if (idLinea) {
                    fetch(`get_maquinarias.php?id_linea=${idLinea}`)
                        .then(response => response.json())
                        .then(data => {
                            maquinaSelect.innerHTML = '<option value="">Seleccione una máquina</option>';
                            data.forEach(maquina => {
                                maquinaSelect.innerHTML += `<option value="${maquina.id_maquinaria}">${maquina.nombre_maquinaria}</option>`;
                            });
                        })
                        .catch(error => console.error('Error al cargar máquinas:', error));
                } else {
                    maquinaSelect.innerHTML = '<option value="">Seleccione una máquina</option>';
                    equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
                }
            });
        }

        if (maquinaSelect) {
            maquinaSelect.addEventListener('change', function () {
                const idMaquina = this.value;
                if (idMaquina) {
                    fetch(`get_equipos.php?id_maquinaria=${idMaquina}`)
                        .then(response => response.json())
                        .then(data => {
                            equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
                            data.forEach(equipo => {
                                equipoSelect.innerHTML += `<option value="${equipo.id_equipo}">${equipo.nombre_equipo}</option>`;
                            });
                        })
                        .catch(error => console.error('Error al cargar equipos:', error));
                } else {
                    equipoSelect.innerHTML = '<option value="">Seleccione un equipo</option>';
                }
            });
        }

        // Validar fechas: no permitir que la fecha de término sea menor a la de inicio
        if (fechaInicioInput && fechaTerminoInput) {
            fechaInicioInput.addEventListener('change', function () {
                if (fechaTerminoInput.value < fechaInicioInput.value) {
                    fechaTerminoInput.value = fechaInicioInput.value;
                }
            });

            fechaTerminoInput.addEventListener('change', function () {
                if (fechaTerminoInput.value < fechaInicioInput.value) {
                    alert('La fecha de término no puede ser menor que la fecha de inicio.');
                    fechaTerminoInput.value = fechaInicioInput.value;
                }
            });
        }
    }
});
