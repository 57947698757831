document.addEventListener('DOMContentLoaded', function () {
    const eliminarBotones = document.querySelectorAll('.btn-eliminar');

    eliminarBotones.forEach(function (button) {
        button.addEventListener('click', function () {
            const idTrabajo = this.getAttribute('data-id');
            
            if (confirm('¿Estás seguro de que deseas eliminar este trabajo?')) {
                // Enviar una solicitud AJAX para eliminar el trabajo
                fetch('../admin/eliminar_trabajo.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({ id: idTrabajo })
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        // Recargar la página o eliminar la fila de la tabla
                        alert('Trabajo eliminado correctamente');
                        location.reload(); // Opción para recargar la página
                    } else {
                        alert('Error al eliminar el trabajo: ' + data.message);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    alert('Error al eliminar el trabajo.');
                });
            }
        });
    });
});