document.addEventListener('DOMContentLoaded', function () {
    const pendientesPage = document.getElementById('pendientes-page');
    
    if (pendientesPage) {
        const modalCerrarOT = document.getElementById('modal-cerrar-ot');
        const closeModal = modalCerrarOT.querySelector('.close-button');
        const formCerrarOT = document.getElementById('form-cerrar-ot');
        const idTrabajoInput = document.getElementById('id_trabajo_modal');

        // Asegurarnos de que el modal esté oculto al cargar la página
        modalCerrarOT.style.display = 'none';

        // Abrir el modal al presionar "Cerrar OT"
        pendientesPage.addEventListener('click', function (event) {
            if (event.target.classList.contains('btn-cerrar-ot')) {
                const idTrabajo = event.target.getAttribute('data-id');
                idTrabajoInput.value = idTrabajo;  // Guardar el ID del trabajo
                modalCerrarOT.style.display = 'flex'; // Mostrar el modal solo cuando se haga clic en "Cerrar OT"
            }
        });

        // Cerrar el modal al hacer clic en la 'x'
        closeModal.addEventListener('click', function () {
            modalCerrarOT.style.display = 'none';
        });

        // Cerrar el modal si se hace clic fuera del contenido
        window.addEventListener('click', function (event) {
            if (event.target === modalCerrarOT) {
                modalCerrarOT.style.display = 'none';
            }
        });

        // Enviar el formulario para cerrar la OT
        formCerrarOT.addEventListener('submit', function (event) {
            event.preventDefault();  // Evitar recarga de la página

            const idTrabajo = idTrabajoInput.value;
            const horaInicio = document.getElementById('hora_inicio').value;
            const horaTermino = document.getElementById('hora_termino').value;
            const fechaTermino = document.getElementById('fecha_termino').value;
            const repuestosUtilizados = document.getElementById('repuestos_utilizados').value;
            const comentarios = document.getElementById('comentarios').value;

            // Enviar los datos a través de Fetch para insertar en datos_ot y actualizar planificador
            fetch('../admin/estado_trabajo_asignado.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id_trabajo: idTrabajo,
                    hora_inicio: horaInicio,
                    hora_termino: horaTermino,
                    fecha_termino: fechaTermino,
                    repuestos_utilizados: repuestosUtilizados,
                    comentarios: comentarios
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert('OT cerrada correctamente');
                    modalCerrarOT.style.display = 'none';  // Cerrar el modal
                    window.location.reload();  // Recargar la página para ver los cambios
                } else {
                    alert('Error al cerrar la OT');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        });
    }
});
