document.addEventListener('DOMContentLoaded', function () {
    const planificadorPage = document.getElementById('planificador-page');

    if (planificadorPage) {
        // Seleccionar todos los botones de "Asignar"
        const botonesAsignar = planificadorPage.querySelectorAll('.btn-asignar-planificador');
        const modalAsignar = planificadorPage.querySelector('#modal-asignar-tecnicos');
        const closeButton = modalAsignar.querySelector('.close-button');
        const formAsignarTecnicos = document.querySelector('#form-asignar-tecnicos');
        let trabajoIdSeleccionado = null;

        // Función para abrir el modal
        function abrirModal() {
            trabajoIdSeleccionado = this.dataset.id;
            modalAsignar.style.display = 'flex';
        }

        function cerrarModal() {
            modalAsignar.style.display = 'none';
        }

        botonesAsignar.forEach(boton => {
            boton.addEventListener('click', abrirModal);
        });

        closeButton.addEventListener('click', cerrarModal);

        window.addEventListener('click', function (event) {
            if (event.target === modalAsignar) {
                cerrarModal();
            }
        });

        formAsignarTecnicos.addEventListener('submit', function (event) {
            event.preventDefault();

            const tecnicosSeleccionados = Array.from(formAsignarTecnicos.querySelectorAll('input[name="tecnicos[]"]:checked'))
                .map(input => input.value);

            if (tecnicosSeleccionados.length > 0) {
                fetch('../admin/asignar_tecnicos.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id_trabajo: trabajoIdSeleccionado,
                        tecnicos: tecnicosSeleccionados.join(', '),
                    }),
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        const filaTrabajo = document.querySelector(`tr[data-id="${trabajoIdSeleccionado}"]`);
                        filaTrabajo.querySelector('td:nth-child(6)').textContent = tecnicosSeleccionados.join(', ');

                        cerrarModal();
                    } else {
                        console.error('Error al asignar técnicos:', data.message);
                    }
                })
                .catch(error => console.error('Error en la solicitud:', error));
            } else {
                alert('Por favor selecciona al menos un técnico.');
            }
        });

        // Lógica para manejar el cambio de estado con el select
        const selectsEstado = planificadorPage.querySelectorAll('.estado-select');

        selectsEstado.forEach(select => {
            select.addEventListener('change', function () {
                const nuevoEstado = this.value;
                const idTrabajo = this.dataset.id;

                // Actualizar el estado en la base de datos
                fetch('../admin/actualizar_estado_planificador.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id_trabajo: idTrabajo,
                        estado: nuevoEstado,
                    }),
                })
                .then(response => response.json())
                .then(data => {
                    if (!data.success) {
                        console.error('Error al actualizar el estado:', data.message);
                    }
                })
                .catch(error => console.error('Error en la solicitud:', error));
            });
        });
    }
});
