document.addEventListener('DOMContentLoaded', function () {
    const maquinariaPage = document.getElementById('maquinaria-page');

    if (maquinariaPage) {
        // MODAL
        const lineaSelect = document.getElementById('linea');
        const detallesContainer = document.querySelector('.maquinaria--detalles');
        const equipoModal = document.getElementById('equipoDetalleModal');
        const trabajosModal = document.getElementById('trabajosRealizadosModal');
        const closeEquipoModalBtn = document.querySelector('.equipo-close-btn');
        const closeTrabajosModalBtn = document.querySelector('.trabajos-close-btn');
        const modalContent = document.querySelector('.equipo-modal-content'); // Actualiza el contenido de este modal
        const trabajosContent = document.getElementById('trabajos-contenido');

        // Comprobar si el select de línea existe antes de añadir el listener
        if (lineaSelect) {
            // Escuchar cambios en la selección de línea
            lineaSelect.addEventListener('change', function () {
                const idLinea = this.value;

                if (idLinea) {
                    // Hacer una petición AJAX para obtener maquinarias de esa línea
                    fetch(`../admin/get_maquinaria_detalle.php?id_linea=${idLinea}`)
                        .then(response => response.json())
                        .then(data => {
                            mostrarMaquinarias(data);
                        });
                } else {
                    detallesContainer.innerHTML = ''; // Limpiar el contenedor si no hay selección
                }
            });
        }

        // Función para mostrar las maquinarias
        function mostrarMaquinarias(data) {
            let html = '';
            data.forEach(maquinaria => {
                html += `
                    <div class="maquinaria-item">
                        <h3>${maquinaria.nombre_maquinaria}</h3>
                        <p><strong>Equipos:</strong></p>
                        <table class="tabla-equipos">
                            <thead>
                                <tr>
                                    <th>Equipo</th>
                                    <th>Detalle</th>
                                    <th>Trabajos Realizados</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${maquinaria.equipos.map(equipo => `
                                    <tr>
                                        <td>${equipo.nombre_equipo}</td>
                                        <td><button class="detalle-btn" data-equipo-id="${equipo.id_equipo}">Detalle</button></td>
                                        <td><button class="trabajos-btn" data-equipo-id="${equipo.id_equipo}">Trabajos Realizados</button></td>
                                    </tr>
                                `).join('')}
                            </tbody>
                        </table>
                    </div>`;
            });
            detallesContainer.innerHTML = html;

            // Añadir listeners a los botones de "Detalle"
            document.querySelectorAll('.detalle-btn').forEach(button => {
                button.addEventListener('click', function () {
                    const idEquipo = this.getAttribute('data-equipo-id');
                    mostrarDetalleEquipo(idEquipo);
                });
            });

            // Añadir listeners a los botones de "Trabajos Realizados"
            document.querySelectorAll('.trabajos-btn').forEach(button => {
                button.addEventListener('click', function () {
                    const idEquipo = this.getAttribute('data-equipo-id');
                    mostrarTrabajosRealizados(idEquipo);
                });
            });
        }



       // Función para mostrar los detalles del equipo
function mostrarDetalleEquipo(idEquipo) {
    // Realizar el fetch para obtener los detalles del equipo
    fetch(`../admin/get_equipo_detalle.php?id_equipo=${idEquipo}`)
        .then(response => response.json())
        .then(data => {
            console.log(data); // Para depuración, imprime los datos recibidos
            
            // Asegúrate de que los campos devueltos existen y no sean undefined
            const nombreEquipo = data.nombre_equipo || 'Sin nombre';
            const partes = data.partes || [];

            // Crear el contenido del modal dinámicamente
            let detallesHTML = `
                <h3>Detalles del equipo: ${nombreEquipo}</h3>
                <p><strong>Descripción:</strong> ${data.descripcion || 'No disponible'}</p>
                <p><strong>Ubicación:</strong> ${data.ubicacion || 'No disponible'}</p>
                <h4>Partes:</h4>
                <ul>
            `;

            // Listar las partes del equipo, si existen
            if (partes.length > 0) {
                partes.forEach(parte => {
                    detallesHTML += `<li>${parte.nombre_parte}</li>`;
                });
            } else {
                detallesHTML += '<li>No se encontraron partes asociadas</li>';
            }

            detallesHTML += '</ul>';

            // Insertar el contenido generado en el modal
            document.querySelector('.equipo-modal-content').innerHTML = detallesHTML;

            // Mostrar el modal
            equipoModal.style.display = 'block';
        })
        .catch(error => {
            console.error('Error al cargar los detalles del equipo:', error);
        });
}

        function mostrarTrabajosRealizados(idEquipo) {
            fetch(`../admin/get_trabajos_realizados.php?id_equipo=${idEquipo}`)
                .then(response => response.json())
                .then(data => {
                    console.log(data); // Esto te ayudará a ver qué datos se están recibiendo
                    if (data.length > 0) {
                        let trabajosHtml = '<ul>';
                        data.forEach(trabajo => {
                            trabajosHtml += `<li>${trabajo.trabajo_realizado} - Fecha: ${trabajo.fecha_termino}</li>`;
                        });
                        trabajosHtml += '</ul>';
                        trabajosContent.innerHTML = trabajosHtml;
                    } else {
                        trabajosContent.innerHTML = '<p>No se encontraron trabajos realizados.</p>';
                    }
                    trabajosModal.style.display = 'block';
                })
                .catch(error => {
                    console.error('Error al cargar los trabajos realizados:', error);
                });
        }

        // Cerrar el modal de equipo
        closeEquipoModalBtn.addEventListener('click', function () {
            equipoModal.style.display = 'none';
        });

        // Cerrar el modal de trabajos realizados
        closeTrabajosModalBtn.addEventListener('click', function () {
            trabajosModal.style.display = 'none';
        });

        // Cerrar el modal cuando se hace clic fuera del contenido
        window.addEventListener('click', function (event) {
            if (event.target == equipoModal) {
                equipoModal.style.display = 'none';
            }
            if (event.target == trabajosModal) {
                trabajosModal.style.display = 'none';
            }
        });
    }
});
