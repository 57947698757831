document.addEventListener('DOMContentLoaded', function () {
    const uptaskPage = document.getElementById('uptask-page');

    if (uptaskPage) {
        const modalAvances = document.getElementById('uptask-modal-avances');
        const modalTarea = document.getElementById('uptask-modal-tarea');
        const modalCompletadas = document.getElementById('uptask-modal-completadas');
        const closeBtns = document.querySelectorAll('.uptask-close-btn');
        let currentTaskId = null;  // Variable para almacenar el ID de la tarea actual
        
        // Abrir modal de avances
        document.querySelectorAll('.uptask-ver-avances-btn').forEach(button => {
            button.addEventListener('click', function () {
                const idTarea = this.getAttribute('data-id');
                currentTaskId = idTarea;  // Guardar el ID de la tarea actual
                fetch(`../admin/get_avances.php?id=${idTarea}`)
                    .then(response => response.json())  // Recibir como JSON
                    .then(data => {
                        let avancesHTML = '';
                        if (data.length > 0) {
                            data.forEach((avance, index) => {
                                avancesHTML += `<li>${index + 1}. ${avance}</li>`;
                            });
                        } else {
                            avancesHTML = '<p>No hay avances aún.</p>';
                        }
                        document.getElementById('uptask-contenido-avances').innerHTML = `<ul>${avancesHTML}</ul>`;
                        modalAvances.style.display = 'block';
                    });
            });
        });

        // Guardar avances
        document.getElementById('uptask-guardar-avance-btn').addEventListener('click', function () {
            const nuevoAvance = document.getElementById('uptask-nuevo-avance').value;
            if (currentTaskId) {
                fetch(`../admin/guardar_avance.php`, {
                    method: 'POST',
                    body: JSON.stringify({ id: currentTaskId, avance: nuevoAvance }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(() => {
                    location.reload();  // Recargar la página después de guardar el avance
                });
            }
        });

        // Abrir modal de añadir tarea
        document.getElementById('uptask-abrir-modal-tarea').addEventListener('click', function () {
            modalTarea.style.display = 'block';
        });

        // Abrir modal de tareas completadas
        document.getElementById('uptask-abrir-modal-completadas').addEventListener('click', function () {
            fetch('../admin/get_tareas_completadas.php')
                .then(response => response.json())
                .then(data => {
                    let html = '';
                    data.forEach(tarea => {
                        html += `<tr>
                                    <td>${tarea.id}</td>
                                    <td>${tarea.tarea}</td>
                                    <td>${tarea.fecha}</td>
                                 </tr>`;
                    });
                    document.querySelector('#uptask-tabla-completadas tbody').innerHTML = html;
                    modalCompletadas.style.display = 'block';
                });
        });

        // Cerrar los modales
        closeBtns.forEach(btn => {
            btn.addEventListener('click', function () {
                btn.closest('.uptask-modal').style.display = 'none';
            });
        });

        // Archivar tarea con confirmación
        document.querySelectorAll('.uptask-archivar-btn').forEach(button => {
            button.addEventListener('click', function () {
                const idTarea = this.getAttribute('data-id');
                const confirmacion = confirm("¿Estás seguro de que deseas archivar esta tarea?");
                if (confirmacion) {
                    fetch(`../admin/archivar_tarea.php?id=${idTarea}`)
                        .then(response => response.text())
                        .then(() => location.reload());
                }
            });
        });

        // Añadir nueva tarea
        document.getElementById('uptask-guardar-tarea-btn').addEventListener('click', function () {
            const tarea = document.getElementById('uptask-nueva-tarea').value;
            const fecha = document.getElementById('uptask-fecha-tarea').value;
            fetch('../admin/anadir_tareas.php', {
                method: 'POST',
                body: JSON.stringify({ tarea, fecha }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(() => {
                location.reload();
            });
        });
    }
});
