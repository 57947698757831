document.addEventListener('DOMContentLoaded', function () {
    const solicitudesPage = document.getElementById('solicitudes-page');

    if (solicitudesPage) {
        console.log("Página de solicitudes cargada");

        // Mostrar errores
        function showError(message) {
            alert(`Error: ${message}`);
            console.error(message);
        }

        // Actualizar el estado de la solicitud
        const estadoSelects = document.querySelectorAll('.estado-select');
        estadoSelects.forEach(select => {
            select.addEventListener('change', function () {
                const id = this.getAttribute('data-id');
                const nuevoEstado = this.value;
                console.log("Cambiando estado para ID:", id, "a estado:", nuevoEstado);

                fetch('../admin/actualizar_estado_solicitud.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ id, estado: nuevoEstado })
                })
                .then(response => response.text())
                .then(data => {
                    alert('Estado actualizado correctamente');
                })
                .catch(error => showError('Error al actualizar el estado'));
            });
        });

        // Buscador de solicitudes
        const inputBuscarSolicitud = document.getElementById('buscar-solicitud');
        const filasTablaSolicitudes = document.querySelectorAll('#tabla-solicitudes tbody tr');

        if (inputBuscarSolicitud) {
            inputBuscarSolicitud.addEventListener('keyup', function () {
                const filtro = inputBuscarSolicitud.value.toLowerCase();
                console.log("Filtro de búsqueda:", filtro);
                filasTablaSolicitudes.forEach(fila => {
                    const celdas = fila.getElementsByTagName('td');
                    let coincide = false;
                    for (let celda of celdas) {
                        if (celda.innerText.toLowerCase().includes(filtro)) {
                            coincide = true;
                            break;
                        }
                    }
                    fila.style.display = coincide ? '' : 'none';
                });
            });
        } else {
            console.log("Campo de búsqueda no encontrado");
        }

        // Abrir el modal para agregar solicitud
        const btnAgregarSolicitud = document.getElementById('btn-agregar-solicitud');
        if (btnAgregarSolicitud) {
            btnAgregarSolicitud.addEventListener('click', abrirModalAgregarSolicitud);
        }

        function abrirModalAgregarSolicitud() {
            fetch('../admin/listar_inventario.php')
                .then(response => response.json())
                .then(data => {
                    const modalAgregar = document.createElement('div');
                    modalAgregar.classList.add('solicitudes-modal-overlay');
                    let options = '';
                    data.forEach(item => {
                        options += `<option value="${item.nombre_item}">${item.nombre_item}</option>`;
                    });

                    modalAgregar.innerHTML = `
                        <div class="solicitudes-modal-content">
                            <form id="form-agregar-solicitud" action="../admin/agregar_solicitud.php" method="POST">
                                <h3>Agregar Solicitud</h3>
                                <label for="numero_solicitud">N° Solicitud:</label>
                                <input type="text" name="numero_solicitud" required>
                                <label for="codigo_sap">Código SAP:</label>
                                <input type="text" name="codigo_sap" required>
                                <label for="nombre_item">Nombre del Ítem:</label>
                                <select name="nombre_item" required>
                                    <option value="" disabled selected>Seleccionar item</option>
                                    ${options}
                                </select>
                                <label for="cantidad">Cantidad:</label>
                                <input type="number" name="cantidad" required>
                                <label for="centro_costo">Centro de Costo:</label>
                                <input type="text" name="centro_costo" required>
                                <label for="faena">Faena:</label>
                                <input type="text" name="faena" required>
                                <label for="solicitado_por">Solicitado por:</label>
                                <select name="solicitado_por" required>
                                    <option value="César Muñoz">César Muñoz</option>
                                    <option value="Ximena Lobos">Ximena Lobos</option>
                                </select>
                                <label for="estado">Estado:</label>
                                <select name="estado" required>
                                    <option value="Solicitado">Solicitado</option>
                                    <option value="Recibido">Recibido</option>
                                    <option value="Atrasado">Atrasado</option>
                                </select>
                                <button type="submit">Agregar Solicitud</button>
                                <button type="button" id="btn-cerrar-modal">Cerrar</button>
                            </form>
                        </div>
                    `;
                    document.body.appendChild(modalAgregar);

                    document.getElementById('btn-cerrar-modal').addEventListener('click', () => {
                        document.body.removeChild(modalAgregar);
                    });

                    document.getElementById('form-agregar-solicitud').addEventListener('submit', function (e) {
                        e.preventDefault();
                        const formData = new FormData(this);

                        fetch('../admin/agregar_solicitud.php', {
                            method: 'POST',
                            body: formData
                        })
                        .then(response => response.text())
                        .then(data => {
                            alert(data);
                            location.reload();
                        })
                        .catch(error => showError('Error al agregar la solicitud'));
                    });
                })
                .catch(error => showError('Error al cargar inventario para el modal'));
        }
    }
});
