document.addEventListener('DOMContentLoaded', function () {
    const cronogramaPage = document.getElementById('cronograma-page');

    if (cronogramaPage) {
        const lineaSelector = document.getElementById('linea-selector');
        const ganttContainer = document.getElementById('gantt-container');

        // Cargar datos al cambiar la línea
        lineaSelector.addEventListener('change', function () {
            const lineaId = this.value;
            fetchGanttData(lineaId);
        });

        // Función para obtener los datos de la Gantt
        function fetchGanttData(lineaId) {
            fetch(`get_gantt.php?id_linea=${lineaId}`)
                .then(response => response.json())
                .then(data => {
                    renderGantt(data);
                })
                .catch(error => console.error('Error fetching Gantt data:', error));
        }

        // Función para renderizar la tabla Gantt
        function renderGantt(data) {
            let ganttHTML = '<table class="gantt-table"><thead><tr>';

            // Generar encabezado con los días del año
            for (let day = 1; day <= 365; day++) {
                ganttHTML += `<th>${day}</th>`;
            }
            ganttHTML += '</tr></thead><tbody>';

            // Generar filas para cada maquinaria/equipo
            data.forEach(equipo => {
                ganttHTML += '<tr>';
                for (let day = 1; day <= 365; day++) {
                    // Si el día coincide con una tarea programada
                    if (equipo.trabajos.includes(day)) {
                        ganttHTML += `<td class="gantt-cell" data-day="${day}" data-equipo="${equipo.id}"></td>`;
                    } else {
                        ganttHTML += '<td></td>';
                    }
                }
                ganttHTML += '</tr>';
            });

            ganttHTML += '</tbody></table>';
            ganttContainer.innerHTML = ganttHTML;
        }

        // Exportar a Excel (lógica simplificada)
        document.getElementById('exportar-excel').addEventListener('click', function () {
            window.location.href = `exportar_gantt.php?id_linea=${lineaSelector.value}`;
        });
    }
});
