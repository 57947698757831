// Código para manejar el modal de detalles de un equipo
console.log('Desde ver_equipo.js');
document.addEventListener('DOMContentLoaded', function () {
    const modal = document.getElementById('equipoDetalleModal');
    const closeModalBtn = document.querySelector('.equipo-close-btn');
    const modalContent = document.querySelector('.equipo-modal-content');

    function mostrarDetalleEquipo(idEquipo) {
        fetch(`../admin/get_equipo_detalle.php?id_equipo=${idEquipo}`)
            .then(response => response.json())
            .then(data => {
                let html = `
                    <h3>${data.nombre_equipo}</h3>
                    <p><strong>Detalles:</strong></p>
                    <ul>
                        ${data.partes.map(parte => `
                            <li>${parte.nombre_parte}</li>`).join('')}
                    </ul>`;
                modalContent.innerHTML = html;
                modal.style.display = 'block'; // Mostrar el modal
            })
            .catch(error => console.error('Error al obtener los detalles del equipo:', error));
    }

    // Cerrar el modal al hacer clic en el botón de cerrar
    if (closeModalBtn) {
        closeModalBtn.addEventListener('click', function () {
            modal.style.display = 'none';
        });
    }

    // Cerrar el modal al hacer clic fuera del contenido del modal
    if (modal) {
        window.addEventListener('click', function (event) {
            if (event.target == modal) {
                modal.style.display = 'none';
            }
        });
    }

    // Exponer la función para que pueda ser llamada desde otros scripts
    window.mostrarDetalleEquipo = mostrarDetalleEquipo;
});