// Verificar si estamos en la página donde se necesita el filtro
if (document.getElementById('trabajos-lista')) {
    // Abrir el modal de filtro
    document.getElementById('btn-filtrar').addEventListener('click', function() {
        document.getElementById('filterModal').style.display = 'block';
    });

    // Cerrar el modal de filtro
    document.querySelector('.close-button').addEventListener('click', function() {
        document.getElementById('filterModal').style.display = 'none';
    });

    // Opcional: cerrar el modal al hacer clic fuera de él
    window.addEventListener('click', function(event) {
        const modal = document.getElementById('filterModal');
        if (event.target === modal) {
            modal.style.display = 'none';
        }
    });

    // Manejo de filtros
    let filters = [];

    // Funcionalidad para el botón de filtrar
    document.getElementById('filterButton').addEventListener('click', function() {
        const filterValue = document.getElementById('filterInput').value.trim();
        if (filterValue && !filters.includes(filterValue)) {
            filters.push(filterValue);
            addFilterTag(filterValue);
            applyFilters();
            document.getElementById('filterInput').value = ''; // Limpiar el campo de entrada
        }
    });

    function addFilterTag(value) {
        const filterTagsContainer = document.getElementById('filterTags');
        const tag = document.createElement('span');
        tag.className = 'filter-tag';
        tag.textContent = value;
        
        // Botón de cierre para la etiqueta
        const closeBtn = document.createElement('span');
        closeBtn.className = 'close-btn';
        closeBtn.textContent = '✖';
        closeBtn.onclick = function() {
            filters = filters.filter(f => f !== value); // Eliminar el filtro
            filterTagsContainer.removeChild(tag); // Eliminar la etiqueta visualmente
            applyFilters(); // Aplicar los filtros restantes
        };
        
        tag.appendChild(closeBtn);
        filterTagsContainer.appendChild(tag);
    }

    function applyFilters() {
        const rows = document.querySelectorAll('.tabla-trabajos__tr');

        rows.forEach(row => {
            const cells = row.getElementsByTagName('td');
            let match = true; // Asumimos que la fila coincide

            filters.forEach(filter => {
                let rowContainsFilter = false;

                for (let i = 0; i < cells.length; i++) {
                    if (cells[i].innerText.toLowerCase().includes(filter.toLowerCase())) {
                        rowContainsFilter = true;
                        break;
                    }
                }

                // Si alguna etiqueta de filtro no coincide, ocultar la fila
                if (!rowContainsFilter) {
                    match = false;
                }
            });

            row.style.display = match ? '' : 'none'; // Muestra o oculta la fila
        });
    }
}
