document.addEventListener('DOMContentLoaded', function () {
    const eSMaterialesPage = document.getElementById('e_s_materiales-page');

    if (eSMaterialesPage) {
        // Agregar Movimiento
        const btnAgregarMovimiento = document.getElementById('btn-agregar-movimiento');
        const btnEliminarMovimiento = document.getElementById('btn-eliminar-movimiento');
        const buscarMovimientoForm = document.getElementById('buscarMovimientoForm');
        const inputBuscarMaterial = document.getElementById('buscar-material');
        const tablaESMateriales = document.getElementById('tabla-e_s-materiales').getElementsByTagName('tbody')[0];

        if (btnAgregarMovimiento) {
            btnAgregarMovimiento.addEventListener('click', function () {
                abrirModalAgregarMovimiento();
            });
        }

        if (btnEliminarMovimiento) {
            btnEliminarMovimiento.addEventListener('click', function () {
                eliminarMovimiento();
            });
        }

        // Función para abrir el modal de agregar movimiento
        function abrirModalAgregarMovimiento() {
            fetch('../admin/listar_inventario.php')  // Hacemos una petición al servidor para obtener el inventario
                .then(response => response.json())
                .then(data => {
                    const modalAgregar = document.createElement('div');
                    modalAgregar.classList.add('e_s-modal-overlay');
                    let options = '';
                    data.forEach(item => {
                        options += `<option value="${item.nombre_item}">${item.nombre_item}</option>`;
                    });

                    modalAgregar.innerHTML = `
                        <div class="e_s-modal-content">
                            <form id="form-agregar-movimiento" action="../admin/agregar_movimiento.php" method="POST">
                                <h3>Agregar Movimiento</h3>
                                
                                <label for="movimiento">Movimiento:</label>
                                <select name="movimiento" required>
                                    <option value="Entrada">Entrada</option>
                                    <option value="Salida">Salida</option>
                                </select>

                                <label for="nombre_item">Nombre del Ítem:</label>
                                <select name="nombre_item" required>
                                    ${options}
                                </select>

                                <label for="detalles">Detalles:</label>
                                <input type="text" name="detalles" required>

                                <label for="cantidad">Cantidad:</label>
                                <input type="number" name="cantidad" required>

                                <label for="trabajo">Trabajo:</label>
                                <input type="text" name="trabajo" required>

                                <button type="submit">Agregar Movimiento</button>
                                <button type="button" id="btn-cerrar-modal">Cerrar</button>
                            </form>
                        </div>
                    `;
                    document.body.appendChild(modalAgregar);

                    document.getElementById('btn-cerrar-modal').addEventListener('click', function () {
                        document.body.removeChild(modalAgregar);
                    });

                    document.getElementById('form-agregar-movimiento').addEventListener('submit', function (e) {
                        e.preventDefault();
                        const formData = new FormData(this);

                        fetch('../admin/agregar_movimiento.php', {
                            method: 'POST',
                            body: formData
                        })
                            .then(response => response.text())
                            .then(data => {
                                alert(data);
                                location.reload();
                            })
                            .catch(error => console.error('Error:', error));
                    });
                });
        }

        // Función para eliminar movimiento
        function eliminarMovimiento() {
            const id = prompt('Ingrese el ID del movimiento a eliminar:');
            if (id) {
                const formData = new FormData();
                formData.append('id', id);

                fetch('../admin/eliminar_movimiento.php', {
                    method: 'POST',
                    body: formData
                })
                    .then(response => response.text())
                    .then(data => {
                        alert(data);
                        location.reload();
                    })
                    .catch(error => console.error('Error:', error));
            }
        }

        // Función de búsqueda
        buscarMovimientoForm.addEventListener('submit', function (e) {
            e.preventDefault();
            const textoBusqueda = inputBuscarMaterial.value.toLowerCase();

            const filas = tablaESMateriales.getElementsByTagName('tr');

            for (let i = 0; i < filas.length; i++) {
                const celdas = filas[i].getElementsByTagName('td');
                let coincide = false;

                for (let j = 0; j < celdas.length; j++) {
                    const contenidoCelda = celdas[j].textContent.toLowerCase();

                    if (contenidoCelda.includes(textoBusqueda)) {
                        coincide = true;
                        break;
                    }
                }

                if (coincide) {
                    filas[i].style.display = '';  // Mostrar fila
                } else {
                    filas[i].style.display = 'none';  // Ocultar fila
                }
            }
        });
    }
});
